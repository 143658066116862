<script lang="ts">
  import { onMount } from "svelte";
  import SectionHeader from "src/components/elements/SectionHeader.svelte";
  import ActionButton from "src/components/elements/ActionButton.svelte";
  import LoadingIndicator from "src/components/elements/LoadingIndicator.svelte";

  export let data: unknown;
  export let fetchData: () => Promise<unknown>;
  export let keepLoading = false;

  let fetchError: boolean = false;

  $: dataFetched = Boolean(data);

  onMount(() => {
    tryFetchData();
  });

  function tryFetchData() {
    fetchError = false;
    fetchData()
      .then((fetchedData) => {
        data = fetchedData;
      })
      .catch((err) => {
        fetchError = true;
        console.error(err);
      });
  }
</script>

{#if dataFetched && !keepLoading}
  <slot />
{:else if fetchError}
  <div
    class="absolute top-0 bottom-0 left-0 right-0 px-4 flex items-center justify-center"
  >
    <div class="w-full md:max-w-xl lg:max-w-3xl">
      <div class="mb-4">
        <SectionHeader title="Well, this is awkward" />
      </div>
      <p>
        Something went wrong while loading this content. Click the button below
        or refresh the page to try again.
      </p>
      <p class="mt-2 text-subtle text-sm">
        If the error persists, please <a
          class="text-accent"
          href="mailto:contact@frankkulak.com">let me know</a
        >.
      </p>
      <div class="mt-6">
        <ActionButton
          text="Reload Content"
          gradient={true}
          onClick={tryFetchData}
        />
      </div>
    </div>
  </div>
{:else}
  <div
    class="absolute top-0 bottom-0 left-0 right-0 px-4 flex items-center justify-center"
  >
    <LoadingIndicator />
  </div>
{/if}
