var DataFetcher;
(function (DataFetcher) {
    const _fetchCache = new Map();
    /**
     * Fetches and caches the JSON data at the given endpoint.
     *
     * @param endpoint Endpoint from which to fetch data
     * @param processor Function to run on data the first time it is fetched
     * @returns Object containing data from JSON at endpoint
     */
    async function fetchJson(endpoint, processor) {
        return new Promise((resolve, reject) => {
            if (_fetchCache.has(endpoint))
                return resolve(_fetchCache.get(endpoint));
            fetch(endpoint)
                .then(response => response.json())
                .then(json => {
                processor?.(json);
                _fetchCache.set(endpoint, json);
                resolve(json);
            })
                .catch(reject);
        });
    }
    DataFetcher.fetchJson = fetchJson;
    /**
     * Returns the JSON data at the given endpoint, if it's cached. If not, then
     * returns undefined.
     *
     * @param endpoint Endpoint from which to get cached data
     * @returns Object containing data from JSON at endpoint
     */
    function getCachedJson(endpoint) {
        return _fetchCache.get(endpoint);
    }
    DataFetcher.getCachedJson = getCachedJson;
    /**
     * Uncaches the data for the given endpoint.
     *
     * @param endpoint Endpoint of data to uncache
     */
    function uncache(endpoint) {
        _fetchCache.delete(endpoint);
    }
    DataFetcher.uncache = uncache;
})(DataFetcher || (DataFetcher = {}));
export default DataFetcher;
