<script lang="ts">
  import type { ProjectDashboardData } from "src/lib/data/home-data";
  import ProjectDashboardCell from "./ProjectDashboardCell.svelte";
  import PortfolioLinkCell from "./PortfolioLinkCell.svelte";

  export let dashboardData: ProjectDashboardData;
</script>

<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
  {#each dashboardData.cells as cell}
    <ProjectDashboardCell {cell} />
  {/each}
  <PortfolioLinkCell linkCell={dashboardData.linkCell} />
</div>
