<script lang="ts">
  export let linkCell: {
    readonly title: string;
    readonly description: string;
  };
</script>

<a
  href="#/projects"
  class="no-underline zoom-on-hover flex flex-col justify-between gap-4 p-4 rounded-md bg-gray-600 dark:bg-gray-900 drop-shadow-sm"
>
  <div>
    <div class="h-8 sm:h-9 flex items-end">
      <h3 class="font-bold text-white">{linkCell.title}</h3>
    </div>
    <p class="mt-2 text-sm text-gray-200 dark:text-gray-400">
      {linkCell.description}
    </p>
  </div>
  <div class="flex justify-end">
    <img src="./assets/icons/arrow-forward.svg" alt="→" class="svg-light h-4" />
  </div>
</a>

<style lang="scss">
  a img {
    transition: all 100ms linear;
  }

  a:hover img {
    margin-left: 3px;
    margin-right: -3px;
  }
</style>
