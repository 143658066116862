<script lang="ts">
  import type { ExperienceSectionData } from "src/lib/data/home-data";
  import ExternalArrowLink from "src/components/elements/ExternalArrowLink.svelte";
  import ExperienceBlock from "./ExperienceBlock.svelte";

  export let experienceData: ExperienceSectionData;

  $: hasAllPositionsLink = Boolean(experienceData.allPositionsLink);
</script>

<div>
  <div class="mb-12 flex flex-col gap-12">
    {#each experienceData.experiences as experience}
      <ExperienceBlock {experience} />
    {/each}
  </div>

  {#if hasAllPositionsLink}
    <ExternalArrowLink
      text={experienceData.allPositionsLink.text}
      href={experienceData.allPositionsLink.href}
      title="View All Experience"
      colorOnHover={false}
    />
  {/if}
</div>
