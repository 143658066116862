/**
 * Transforms the given list of strings into a single string where items are
 * bolded, separated by commas, and the final word has a conjunction before it.
 *
 * @param list List of strings to format
 * @param conjunction Conjunction to join final words
 */
export function formatListAsHtml(list, conjunction) {
    if (list.length === 0)
        return;
    const boldedList = list.map(s => `<strong class="text-color">${s}</strong>`);
    if (list.length === 1)
        return boldedList[0];
    if (list.length === 2)
        return `${boldedList[0]} ${conjunction} ${boldedList[1]}`;
    const finalIndex = boldedList.length - 1;
    boldedList[finalIndex] = `${conjunction} ${boldedList[finalIndex]}`;
    return boldedList.join(", ");
}
