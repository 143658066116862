<footer class="pt-24 flex flex-col gap-4">
  <p class="text-subtle text-sm">
    Created with <span class="text-pink-500">&#9829;</span> using
    <a href="https://svelte.dev/" target="_blank">Svelte</a>
    and
    <a href="https://tailwindcss.com/" target="_blank">TailwindCSS</a>, hosted
    on <a href="https://pages.github.com/" target="_blank">GitHub Pages</a>. All
    icons sourced from
    <a href="https://ionic.io/ionicons" target="_blank">Ionicons</a>. Layout and
    design inspired by
    <a href="https://brittanychiang.com/" target="_blank">Brittany Chiang</a>.
  </p>
  <p class="text-subtle text-sm">2023-2024 © Frank Kulak</p>
</footer>
