<script lang="ts">
  import type { HomePageData } from "src/lib/data/home-data";
  import Footer from "src/components/Footer.svelte";
  import HrTitle from "src/components/elements/HrTitle.svelte";
  import AboutContentSection from "./sections/about/AboutContentSection.svelte";
  import ExperienceContentSection from "./sections/experience/ExperienceContentSection.svelte";
  import ProjectsContentSection from "./sections/projects/ProjectsContentSection.svelte";
  import SkillsContentSection from "./sections/skills/SkillsContentSection.svelte";

  export let homeData: HomePageData;
</script>

<main
  class="flex-1 flex flex-col w-full md:w-2/3 lg:w-7/12 xl:w-1/2 pb-24 lg:px-6 px-4"
>
  <section id="about" class="pt-24">
    <div class="w-full mb-12 md:mb-0 md:hidden">
      <HrTitle title={homeData.about.headingName} />
    </div>
    <AboutContentSection aboutData={homeData.about} />
  </section>

  <section id="experience" class="pt-24">
    <div class="w-full mb-12">
      <HrTitle title={homeData.experience.headingName} />
    </div>
    <ExperienceContentSection experienceData={homeData.experience} />
  </section>

  <section id="projects" class="pt-24">
    <div class="w-full mb-12">
      <HrTitle title={homeData.projects.headingName} />
    </div>
    <ProjectsContentSection projectsData={homeData.projects} />
  </section>

  <section id="skills" class="pt-24">
    <div class="w-full mb-12">
      <HrTitle title={homeData.skills.headingName} />
    </div>
    <SkillsContentSection skillsData={homeData.skills} />
  </section>

  <Footer />
</main>
