<script lang="ts">
  import type { HomePageData } from "src/lib/data/home-data";
  import HeaderAnchor from "./HeaderAnchor.svelte";

  export let homeData: HomePageData;
  export let activeSectionId: string;
</script>

<nav class="hidden md:block">
  <ul class="flex flex-col gap-2">
    <HeaderAnchor
      title={homeData.about.navName}
      sectionId="about"
      {activeSectionId}
      index={0}
    />
    <HeaderAnchor
      title={homeData.experience.navName}
      sectionId="experience"
      {activeSectionId}
      index={1}
    />
    <HeaderAnchor
      title={homeData.projects.navName}
      sectionId="projects"
      {activeSectionId}
      index={2}
    />
    <HeaderAnchor
      title={homeData.skills.navName}
      sectionId="skills"
      {activeSectionId}
      index={3}
    />
  </ul>
</nav>
