<script lang="ts">
  import endpoints from "src/lib/data/endpoints";
  import type { SocialLinkInfo } from "src/lib/data/home-data";

  export let linkData: SocialLinkInfo;

  $: iconSrc = endpoints.resolveIcon(linkData.icon);
</script>

<a title={linkData.name} href={linkData.href} target="_blank"
  ><img
    alt={linkData.name}
    class="svg h-8 w-8 tint-on-hover"
    src={iconSrc}
  /></a
>
