<script lang="ts">
  import CollapsedSettingsPreview from "./CollapsedSettingsPreview.svelte";
  import SettingsPanelBody from "./SettingsPanelBody.svelte";

  export let availableTagFilters: readonly string[];
  export let availableSkillFilters: readonly string[];

  let bottomTrayExpanded = false;
</script>

<div
  class="fixed bottom-0 left-0 right-0 top-12 z-20 md:sticky md:top-0 md:h-screen w-full md:w-2/5 lg:w-1/3 flex items-end md:items-center md:justify-center md:px-6 md:py-24 pointer-events-none"
>
  <div
    id="settings-panel"
    class="flex flex-col w-full md:!h-full rounded-t-lg md:rounded-lg drop-shadow bg-gray-600 dark:bg-gray-900 p-4 pointer-events-auto"
    class:h-14={!bottomTrayExpanded}
    class:h-full={bottomTrayExpanded}
  >
    <CollapsedSettingsPreview bind:expanded={bottomTrayExpanded} />
    <div
      class="h-full overflow-y-auto md:!block"
      class:hidden={!bottomTrayExpanded}
    >
      <SettingsPanelBody {availableTagFilters} {availableSkillFilters} />
    </div>
  </div>
</div>

<style lang="scss">
  #settings-panel {
    transition: height 150ms ease-in-out;
  }
</style>
