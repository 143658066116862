import { vaultRepo } from "src/lib/utils/settings";
export default class ProjectVaultState {
    constructor(_projectsData, _onUiRefreshNeeded) {
        this._projectsData = _projectsData;
        this._onUiRefreshNeeded = _onUiRefreshNeeded;
        this._subscriptions = [
            vaultRepo.subscriptions.subscribe("compactView", (compactView) => {
                this._refreshFilters({ compactView });
            }),
            vaultRepo.subscriptions.subscribe("featuredOnly", (featuredOnly) => {
                this._refreshFilters({ featuredOnly });
            }),
            vaultRepo.subscriptions.subscribe("tagFilter", (tagFilter) => {
                this._refreshFilters({ tagFilter });
            }),
            vaultRepo.subscriptions.subscribe("techStackFilter", (techStackFilter) => {
                this._refreshFilters({ techStackFilter });
            }),
        ];
        this._refreshFilters();
    }
    dispose() {
        this._subscriptions.forEach(unsub => unsub());
    }
    _refreshFilters(options) {
        const compactView = options?.compactView ?? vaultRepo.settings.compactView;
        const featuredOnly = options?.featuredOnly ?? vaultRepo.settings.featuredOnly;
        const tagFilter = options?.tagFilter ?? vaultRepo.settings.tagFilter;
        const techStackFilter = options?.techStackFilter ?? vaultRepo.settings.techStackFilter;
        let hiddenByFilters = 0;
        const sections = [];
        const statusCountMap = new Map([
            ["wip", 0],
            ["maintained", 0],
            ["archived", 0],
            ["retired", 0],
        ]);
        this._projectsData.sections.forEach(section => {
            hiddenByFilters += section.projects.length;
            const projects = [...section.projects].filter(project => {
                if (featuredOnly && !project.featured)
                    return false;
                if (tagFilter.length > 0) {
                    if (!project.tags.some(tag => tagFilter.includes(tag)))
                        return false;
                }
                if (techStackFilter.length > 0) {
                    if (!project.techStack.some(skill => techStackFilter.includes(skill)))
                        return false;
                }
                statusCountMap.set(project.status, (statusCountMap.get(project.status) ?? 0) + 1);
                --hiddenByFilters;
                return true;
            });
            if (projects.length > 0)
                sections.push({ title: section.title, projects });
        });
        const statusCounts = [];
        statusCountMap.forEach((count, status) => {
            if (count > 0)
                statusCounts.push({ count, status });
        });
        this._renderData = { compactView, hiddenByFilters, sections, statusCounts };
        this._onUiRefreshNeeded(this._renderData);
    }
}
