<script lang="ts">
  export let text: string;
  export let href: string;
  export let title: string = text;
  export let colorOnHover = true;
</script>

<a
  {href}
  {title}
  target="_blank"
  class="no-underline text-color"
  class:color-on-hover={colorOnHover}
  >{text}<img
    src="./assets/icons/arrow-up-right.svg"
    alt="↗"
    class="svg h-4 inline-block"
  /></a
>

<style lang="scss">
  img {
    transition: all 100ms linear;
  }

  a:hover {
    &.color-on-hover {
      color: var(--color-accent);

      img {
        filter: var(--filter-svg-accent-secondary);
      }
    }

    img {
      margin-left: 3px;
      margin-right: -3px;
      margin-bottom: 3px;
    }
  }
</style>
