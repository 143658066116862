<div
  class="pointer-events-none fixed top-0 bottom-0 left-0 right-0 -z-10 opacity-5"
>
  <img
    src="./assets/home-decals/top-left.svg"
    alt=""
    class="absolute left-0 top-6"
  />
  <img
    src="./assets/home-decals/top-right.svg"
    alt=""
    class="absolute top-0 right-8 sm:right-16"
  />
  <img
    src="./assets/home-decals/bottom-left.svg"
    alt=""
    class="absolute left-0 bottom-24"
  />
</div>
