<script lang="ts">
  export let skills: readonly string[];
  export let size: "sm" | "xs" = "sm";
  export let limit: number = null;

  $: hasOverflow = limit && skills.length > limit;
  $: skillsToShow = hasOverflow ? skills.slice(0, limit) : skills;
</script>

<div class="flex flex-wrap gap-2" class:!gap-1={size === "xs"}>
  {#each skillsToShow as skill}
    <p
      class="bg-sky-200 text-sky-900 dark:bg-sky-900 dark:text-sky-200 max-w-fit px-2 py-1 rounded-full text-{size}"
    >
      {skill}
    </p>
  {/each}
  {#if hasOverflow}
    <p class="mt-1 text-{size} text-subtle">&mldr;</p>
  {/if}
</div>
