<script lang="ts">
  import endpoints from "src/lib/data/endpoints";
  import type { BioSectionData } from "src/lib/data/home-data";

  export let bioData: BioSectionData;

  $: selfPicSrc = endpoints.resolveImage(bioData.picture);
</script>

<div>
  <div class="flex flex-col lg:flex-row gap-4 items-center">
    <img
      src={selfPicSrc}
      alt="Frank Kulak"
      class="h-36 md:h-24 w-36 md:w-24 rounded-full shadow-md"
    />
    <div class="text-center lg:text-left">
      <h1 class="text-2xl lg:text-4xl font-bold text-gradient">
        {bioData.heading}
      </h1>
      <p class="mt-2">{bioData.subheading}</p>
    </div>
  </div>
  <p
    class="text-subtle max-w-sm md:mt-4 mt-8 text-center lg:text-left text-sm lg:text-base"
  >
    {bioData.tagline}
  </p>
</div>
