import config from "src/lib/config";
const BASE_URL = config.DEV
    ? "http://127.0.0.1:5500"
    : "https://raw.githubusercontent.com/frankkulak/portfolio-web-data/prod";
const resolveEndpoint = (endpoint) => `${BASE_URL}/${endpoint}`;
export default {
    homeData: resolveEndpoint("build/home.json"),
    projectsData: resolveEndpoint("build/projects.json"),
    enumsData: resolveEndpoint("build/enums.json"),
    resolveImage: (name) => resolveEndpoint(`images/${name}`),
    resolveIcon: (name) => resolveEndpoint(`images/icons/${name}.svg`),
    resolveSpotlight: (name) => resolveEndpoint(`build/spotlights/${name}`),
};
