<script lang="ts">
  import { onDestroy } from "svelte";
  import { storageRepo } from "src/lib/utils/settings";

  let isLightTheme = storageRepo.settings.isLightTheme;
  $: themeIcon = isLightTheme ? "sunny" : "moon";

  const subscriptions = [
    storageRepo.subscriptions.subscribe("isLightTheme", (value) => {
      isLightTheme = value;
    }),
  ];

  onDestroy(() => {
    subscriptions.forEach((unsub) => unsub());
  });

  function toggleTheme() {
    storageRepo.settings.isLightTheme = !storageRepo.settings.isLightTheme;
  }
</script>

<button on:click={toggleTheme} class="sm:opacity-80 sm:hover:opacity-100">
  <img
    src="./assets/icons/{themeIcon}-outline.svg"
    alt={themeIcon}
    class="svg h-5"
  />
</button>
