<script lang="ts">
  import { onMount, onDestroy } from "svelte";
  import type { ProjectVaultRenderData } from "src/lib/vault/vault-state";
  import HrTitle from "src/components/elements/HrTitle.svelte";
  import ProjectView from "./ProjectView.svelte";
  import ProjectViewCompact from "./ProjectViewCompact.svelte";

  export let vaultData: ProjectVaultRenderData;

  $: atLeastOneSection = vaultData.sections.length > 0;

  let observer: IntersectionObserver;
  let mounted = false;

  $: {
    if (mounted && vaultData) {
      setTimeout(refreshObserver, 500); // HACK: not ideal, but it works
    }
  }

  onMount(() => {
    mounted = true;
  });

  onDestroy(() => {
    observer.disconnect();
  });

  function refreshObserver() {
    observer?.disconnect();

    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.classList.toggle(
            "blurred-bg",
            entry.intersectionRatio < 1
          );
        });
      },
      { rootMargin: "-10px", threshold: [1] }
    );

    const titles = document.querySelectorAll("#project-vault-list > .sticky");

    titles.forEach((title) => {
      observer.observe(title);
    });
  }
</script>

<main
  id="project-vault-list"
  class="flex flex-col gap-8"
  class:!gap-4={vaultData.compactView}
>
  {#if atLeastOneSection}
    {#each vaultData.sections as section}
      <div class="w-full sticky z-10 top-0">
        <HrTitle title={section.title} />
      </div>
      {#each section.projects as project, index}
        {#if index > 0}
          <hr class="border-gray-200 dark:border-gray-700" />
        {/if}
        {#if vaultData.compactView}
          <ProjectViewCompact {project} />
        {:else}
          <ProjectView {project} />
        {/if}
      {/each}
    {/each}
  {:else}
    <div>
      <hr class="border-gray-200 dark:border-gray-700" />
      <p class="mt-12 mb-4">No projects match the chosen filters.</p>
    </div>
  {/if}
</main>
