<script lang="ts">
  import type { ProjectStatus } from "src/lib/data/projects-data";

  export let status: ProjectStatus | "--hidden";
  export let count: number = null;

  $: countText = count == null ? "" : `${count} `;
</script>

{#if status === "wip"}
  <p
    class="border-fuchsia-600 dark:border-fuchsia-500 text-fuchsia-600 dark:text-fuchsia-500"
  >
    {countText}WIP
  </p>
{:else if status === "maintained"}
  <p
    class="border-emerald-600 dark:border-emerald-500 text-emerald-600 dark:text-emerald-500"
  >
    {countText}Maintained
  </p>
{:else if status === "archived"}
  <p
    class="border-amber-600 dark:border-yellow-500 text-amber-600 dark:text-yellow-500"
  >
    {countText}Archived
  </p>
{:else if status === "retired"}
  <p class="border-red-600 dark:border-red-500 text-red-600 dark:text-red-500">
    {countText}Retired
  </p>
{:else}
  <p
    class="border-gray-600 dark:border-gray-500 text-gray-600 dark:text-gray-500"
  >
    {countText}Filtered Out
  </p>
{/if}

<style lang="scss">
  p {
    border: solid;
    border-width: 1px;
    font-weight: normal;
    display: inline-block;
    max-width: fit-content;
    border-radius: 9999px;
    white-space: nowrap;
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding: {
      left: 0.4rem;
      right: 0.4rem;
      top: 0.1rem;
      bottom: 0.1rem;
    }
  }
</style>
