<script lang="ts">
  export let filters: readonly string[];
  export let getFilterText: (value: string) => string = (v) => v;
  export let chosenFilters: Set<string>;

  function toggleFilter(filter: string) {
    if (chosenFilters.has(filter)) chosenFilters.delete(filter);
    else chosenFilters.add(filter);
    chosenFilters = chosenFilters;
  }

  function isFaded(chosenFilters: Set<string>, filter: string): boolean {
    // pass in chosenFilters to force rebuild when updated
    return !chosenFilters.has(filter);
  }
</script>

<div class="flex flex-wrap gap-2">
  {#each filters as filter}
    <button
      class="bg-sky-200 text-sky-900 dark:bg-sky-900 dark:text-sky-200 max-w-fit px-2 py-1 rounded-full text-xs"
      class:opacity-50={isFaded(chosenFilters, filter)}
      on:click={() => toggleFilter(filter)}>{getFilterText(filter)}</button
    >
  {/each}
</div>
