<script lang="ts">
  import { vaultRepo } from "src/lib/utils/settings";
  import type { EnumsData } from "src/lib/data/enums-data";
  import fetchEnumsData from "src/lib/data/enums-data";
  import type { ProjectsPageData } from "src/lib/data/projects-data";
  import fetchProjectsPageData from "src/lib/data/projects-data";
  import DataFetchBlock from "src/components/views/DataFetchBlock.svelte";
  import ProjectVaultLayout from "./ProjectVaultLayout.svelte";

  let enumsData: EnumsData;
  let projectsData: ProjectsPageData;
  let queryParamsHandled = false;

  $: {
    if (enumsData) processQueryParams();
  }

  function processQueryParams() {
    try {
      const query = window.location.hash.split("?", 2)[1];
      if (!query) return;
      const urlParams = new URLSearchParams(query);
      if (urlParams.has("compact")) vaultRepo.settings.compactView = true;
      vaultRepo.settings.featuredOnly = urlParams.has("featured");
      const parseList = (param: string, obj: object) =>
        urlParams
          .get(param)
          ?.split(",")
          .filter((tag) => obj[tag]) ?? [];
      vaultRepo.settings.tagFilter = parseList("tags", enumsData.tags);
      vaultRepo.settings.techStackFilter = parseList("tech", enumsData.skills);
    } catch (e) {
      console.error(e);
    } finally {
      window.location.hash = "#/projects";
      queryParamsHandled = true;
    }
  }
</script>

<svelte:head>
  <title>Frank Kulak | Projects</title>
</svelte:head>

<DataFetchBlock bind:data={enumsData} fetchData={fetchEnumsData}>
  <DataFetchBlock
    bind:data={projectsData}
    fetchData={fetchProjectsPageData}
    keepLoading={!queryParamsHandled}
  >
    <ProjectVaultLayout {projectsData} />
  </DataFetchBlock>
</DataFetchBlock>
