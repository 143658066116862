<script lang="ts">
  import type { ProjectsSectionData } from "src/lib/data/home-data";
  import ProjectDashboard from "./ProjectDashboard.svelte";

  export let projectsData: ProjectsSectionData;
</script>

<div>
  {#if projectsData.summary}
    <p class="mb-8">{projectsData.summary}</p>
  {/if}
  <ProjectDashboard dashboardData={projectsData.dashboard} />
</div>
