<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import type { HomePageData } from "src/lib/data/home-data";
  import Header from "./header/Header.svelte";
  import MainContent from "./content/MainContent.svelte";

  export let homeData: HomePageData;

  let activeSectionId = "";
  let observer: IntersectionObserver;

  onMount(() => {
    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            activeSectionId = entry.target.id;
          }
        });
      },
      { rootMargin: "-50% 0px", threshold: 0 }
    );

    const sections = document.querySelectorAll("section");

    sections.forEach((section) => {
      observer.observe(section);
    });
  });

  onDestroy(() => {
    observer.disconnect();
  });
</script>

<div class="md:flex md:justify-between gap-4">
  <Header {homeData} {activeSectionId} />
  <MainContent {homeData} />
</div>
