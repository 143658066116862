<script lang="ts">
  import { vaultRepo } from "src/lib/utils/settings";
  import { formatProjectSkill, formatProjectTag } from "src/lib/vault/helpers";
  import { formatListAsHtml } from "src/lib/utils/helpers";
  import type { ProjectVaultRenderData } from "src/lib/vault/vault-state";
  import type { ProjectsPageHeaderData } from "src/lib/data/projects-data";
  import ArrowLink from "src/components/elements/ArrowLink.svelte";
  import ProjectStatusIndicator from "./ProjectStatusIndicator.svelte";

  export let headerData: ProjectsPageHeaderData;
  export let vaultData: ProjectVaultRenderData;

  // `vaultData &&` for triggering refresh
  $: filterText = vaultData && formatFilterText();

  function formatFilterText(): string {
    const featuredOnly = vaultRepo.settings.featuredOnly;
    const techStackFilter = vaultRepo.settings.techStackFilter;
    const tagFilter = vaultRepo.settings.tagFilter;

    const skills = formatListAsHtml(
      techStackFilter.map((skill) => formatProjectSkill(skill)),
      "and/or"
    );

    const tags = formatListAsHtml(
      tagFilter.map((tag) => formatProjectTag(tag, { plural: true })),
      "and"
    );

    if (!(featuredOnly || skills || tags)) return headerData.body;

    const builder: string[] = ["Showing my"];
    if (featuredOnly) builder.push("favorite");
    if (tags) builder.push(tags);
    else builder.push("projects");
    if (skills) builder.push("made with", skills);
    return builder.join(" ") + ".";
  }
</script>

<div class="mb-12">
  <div class="mb-8">
    <ArrowLink text={headerData.homeButton} href="#" direction="back" />
    <h1
      class="mt-4 text-2xl sm:text-3xl md:text-4xl font-bold text-gradient max-w-fit whitespace-nowrap"
    >
      {headerData.title}
    </h1>
  </div>
  <p class="mb-4">
    {@html filterText}
  </p>
  <div class="flex flex-wrap gap-1">
    {#each vaultData.statusCounts as statusCount}
      {#if statusCount.count > 0}
        <ProjectStatusIndicator
          status={statusCount.status}
          count={statusCount.count}
        />
      {/if}
    {/each}
    {#if vaultData.hiddenByFilters > 0}
      <ProjectStatusIndicator
        status="--hidden"
        count={vaultData.hiddenByFilters}
      />
    {/if}
  </div>
</div>
