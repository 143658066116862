<script lang="ts">
  import type { ProjectsPageData } from "src/lib/data/projects-data";
  import { getAvailableFilters } from "src/lib/vault/helpers";
  import ProjectVaultContent from "./content/ProjectVaultContent.svelte";
  import SettingsPanel from "./settings/SettingsPanel.svelte";

  export let projectsData: ProjectsPageData;

  const availableFilters = getAvailableFilters(projectsData);
</script>

<div class="md:flex md:justify-between gap-4">
  <ProjectVaultContent {projectsData} />
  <SettingsPanel
    availableTagFilters={availableFilters.tags}
    availableSkillFilters={availableFilters.skills}
  />
</div>
