<script lang="ts">
  import endpoints from "src/lib/data/endpoints";
  import type { AboutSectionGraphicInfo } from "src/lib/data/home-data";

  export let graphicInfo: AboutSectionGraphicInfo;

  $: hasSecondLabel = Boolean(graphicInfo.label2);
  $: iconSrc = endpoints.resolveIcon(graphicInfo.icon);
</script>

<div class="flex flex-col items-center gap-2 text-center z-0">
  <img src={iconSrc} alt={graphicInfo.alt} class="svg h-10" />
  <div>
    <p class="text-subtle text-sm w-36">
      {graphicInfo.label}
    </p>
    {#if hasSecondLabel}
      <p class="text-subtle text-sm w-36">
        {graphicInfo.label2}
      </p>
    {/if}
  </div>
</div>
