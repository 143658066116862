<script lang="ts">
  import type { HomePageData } from "src/lib/data/home-data";
  import BioSection from "./BioSection.svelte";
  import HeaderNavigation from "./HeaderNavigation.svelte";
  import SocialLink from "./SocialLink.svelte";

  export let homeData: HomePageData;
  export let activeSectionId: string;
</script>

<header
  class="md:sticky md:top-0 md:max-h-screen w-full md:w-1/3 lg:w-5/12 xl:w-1/2 w- flex items-center justify-center px-6 pt-24 md:pb-24"
>
  <div
    class="flex-1 h-full flex flex-col gap-8 md:gap-0 justify-between md:items-start items-center"
  >
    <BioSection bioData={homeData.bio} />
    <HeaderNavigation {homeData} {activeSectionId} />
    <div class="flex gap-4">
      {#each homeData.bio.socialLinks as linkData}
        <SocialLink {linkData} />
      {/each}
    </div>
  </div>
</header>
