<script lang="ts">
  import { onDestroy } from "svelte";
  import type { ProjectsPageData } from "src/lib/data/projects-data";
  import ProjectVaultState, {
    ProjectVaultRenderData,
  } from "src/lib/vault/vault-state";
  import ProjectVaultList from "./ProjectVaultList.svelte";
  import ProjectVaultHeader from "./ProjectVaultHeader.svelte";

  export let projectsData: ProjectsPageData;

  let vaultData: ProjectVaultRenderData;
  $: vaultDataReady = Boolean(vaultData);

  const vaultState = new ProjectVaultState(projectsData, (data) => {
    vaultData = data;
  });

  onDestroy(() => {
    vaultState.dispose();
  });
</script>

<div class="flex-1 flex flex-col w-full md:w-3/5 lg:w-2/3 py-24 px-4 lg:px-6">
  {#if vaultDataReady}
    <ProjectVaultHeader headerData={projectsData.header} {vaultData} />
    <ProjectVaultList {vaultData} />
  {/if}
</div>
