<script lang="ts">
  import type { AboutSectionData } from "src/lib/data/home-data";
  import InlineLink from "src/components/elements/InlineLink.svelte";
  import LabeledGraphicItem from "./LabeledGraphicItem.svelte";

  export let aboutData: AboutSectionData;
</script>

<div>
  <div class="flex flex-col gap-4 mb-8">
    {#each aboutData.content as contentBlock}
      <p>
        {#each contentBlock as item}
          {#if Boolean(item.href)}
            <InlineLink href={item.href}>{item.text}</InlineLink>
          {:else}
            {item.text}
          {/if}
        {/each}
      </p>
    {/each}
  </div>
  <div class="flex flex-wrap justify-center gap-8 mx-auto">
    {#each aboutData.graphics as graphicInfo}
      <LabeledGraphicItem {graphicInfo} />
    {/each}
  </div>
</div>
