<script lang="ts">
  import type { ProjectData } from "src/lib/data/projects-data";
  import {
    formatProjectSkill,
    formatProjectSkills,
    formatProjectTags,
  } from "src/lib/vault/helpers";
  import SkillPills from "src/components/views/SkillPills.svelte";
  import ExternalArrowLink from "src/components/elements/ExternalArrowLink.svelte";
  import ProjectStatusIndicator from "./ProjectStatusIndicator.svelte";

  export let project: ProjectData;

  $: tagsText = formatProjectTags(project.tags);
  $: techStackNames = project.techStack.map((skill) =>
    formatProjectSkill(skill)
  );
  $: oldTechStackText =
    project.oldTechStack?.length && formatProjectSkills(project.oldTechStack);
</script>

<div>
  <div class="flex gap-4">
    <div class="flex-1">
      <p class="text-xs text-subtle">
        {#if project.featured}
          <img
            alt="*"
            src="./assets/icons/sparkles-yellow.svg"
            title="This is one of my favorites!"
            class="inline h-3 mr-2"
          />
        {/if}
        {tagsText}
      </p>
      <h3 class="text-lg font-bold">
        {#if Boolean(project.webLink)}
          <ExternalArrowLink
            text={project.name}
            href={project.webLink}
            title="View Online"
          />
        {:else}
          {project.name}
        {/if}
        <ProjectStatusIndicator status={project.status} />
      </h3>
    </div>
    {#if Boolean(project.repoLink)}
      <div>
        <a href={project.repoLink} target="_blank" title="View on GitHub"
          ><img
            alt="Repository"
            src="./assets/icons/logo-github.svg"
            class="svg h-6 tint-on-hover"
          /></a
        >
      </div>
    {/if}
  </div>
  <p class="mt-2 text-subtle">{project.summary}</p>
  <div class="mt-4">
    <SkillPills skills={techStackNames} />
    {#if oldTechStackText}
      <p class="mt-2 text-xs text-subtle">
        Formerly: {oldTechStackText}
      </p>
    {/if}
  </div>
</div>
