<script lang="ts">
  import SkillPills from "src/components/views/SkillPills.svelte";
  import type { ProjectDashboardCellData } from "src/lib/data/home-data";

  export let cell: ProjectDashboardCellData;
</script>

<div
  class="flex flex-col justify-between gap-4 p-4 rounded-md border border-solid border-gray-300 dark:border-gray-700 drop-shadow-sm"
>
  <div>
    <div class="flex items-end gap-4">
      <h2 class="text-2xl sm:text-3xl font-bold">{cell.count}</h2>
      <h3 class="line-clamp-1" style="margin-bottom: 2px;">
        {cell.title}
      </h3>
    </div>
    <p class="mt-2 text-sm text-subtle">{@html cell.description}</p>
  </div>
  <SkillPills skills={cell.techStack} size="xs" />
</div>
