<script lang="ts">
  import { vaultRepo } from "src/lib/utils/settings";
  import { formatProjectTag, formatProjectSkill } from "src/lib/vault/helpers";
  import Switch from "src/components/elements/Switch.svelte";
  import FilterSelector from "./FilterSelector.svelte";

  export let availableTagFilters: readonly string[];
  export let availableSkillFilters: readonly string[];

  let compactView = vaultRepo.settings.compactView;
  $: {
    vaultRepo.settings.compactView = compactView;
  }

  let featuredOnly = vaultRepo.settings.featuredOnly;
  $: {
    vaultRepo.settings.featuredOnly = featuredOnly;
  }

  let chosenTagFilters = new Set<string>(vaultRepo.settings.tagFilter);
  $: {
    vaultRepo.settings.tagFilter = [...chosenTagFilters];
  }

  let chosenSkillFilters = new Set<string>(vaultRepo.settings.techStackFilter);
  $: {
    vaultRepo.settings.techStackFilter = [...chosenSkillFilters];
  }

  $: someFilterApplied =
    featuredOnly || chosenTagFilters.size > 0 || chosenSkillFilters.size > 0;

  function clearFilters() {
    featuredOnly = false;
    chosenTagFilters = new Set();
    chosenSkillFilters = new Set();
  }
</script>

<div class="w-full h-full flex flex-col justify-between">
  <div>
    <div class="flex flex-col gap-2">
      <Switch
        forceTheme="dark"
        label="Use compact view"
        bind:checked={compactView}
      />
      <Switch
        forceTheme="dark"
        label="Show favorite projects only"
        bind:checked={featuredOnly}
      />
    </div>
    <hr class="my-4 border-gray-500 dark:border-gray-800" />
    <div>
      <h4 class="mb-2 md:mb-4 text-sm text-white md:text-base">
        Filter by Project Type
      </h4>
      <FilterSelector
        filters={availableTagFilters}
        getFilterText={formatProjectTag}
        bind:chosenFilters={chosenTagFilters}
      />
    </div>
    <hr class="my-4 border-gray-500 dark:border-gray-800" />
    <div>
      <h4 class="mb-2 md:mb-4 text-sm text-white md:text-base">
        Filter by Tech Stack
      </h4>
      <FilterSelector
        filters={availableSkillFilters}
        getFilterText={formatProjectSkill}
        bind:chosenFilters={chosenSkillFilters}
      />
    </div>
  </div>
  {#if someFilterApplied}
    <div>
      <hr class="my-4 border-gray-500 dark:border-gray-800" />
      <button
        class="whitespace-nowrap flex items-center gap-2 tint-on-hover"
        on:click={clearFilters}
        ><img
          src="./assets/icons/close.svg"
          alt="X"
          class="svg-light h-4"
          style="margin-top: 1px;"
        />
        <p class="text-sm text-white md:text-base whitespace-nowrap">
          Clear Filters
        </p></button
      >
    </div>
  {/if}
</div>
