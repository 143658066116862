<script lang="ts">
  import type { ProjectData } from "src/lib/data/projects-data";
  import {
    formatProjectSkills,
    formatProjectTags,
  } from "src/lib/vault/helpers";
  import ExternalArrowLink from "src/components/elements/ExternalArrowLink.svelte";
  import ProjectStatusIndicator from "./ProjectStatusIndicator.svelte";

  export let project: ProjectData;

  $: tagsText = formatProjectTags(project.tags, { compact: true });
  $: skillsText = formatProjectSkills(project.techStack, { compact: true });
</script>

<div class="flex flex-col gap-1">
  <div class="flex justify-between items-center">
    <div>
      <h3 class="font-bold">
        {#if project.featured}
          <img
            alt="*"
            src="./assets/icons/sparkles-yellow.svg"
            title="This is one of my favorites!"
            class="inline h-3 mr-1"
          />
        {/if}
        {#if Boolean(project.webLink)}
          <ExternalArrowLink
            text={project.name}
            href={project.webLink}
            title="View Online"
          />
        {:else}
          {project.name}
        {/if}
        <span class="hidden sm:inline">
          <ProjectStatusIndicator status={project.status} />
        </span>
      </h3>
    </div>
    {#if Boolean(project.repoLink)}
      <div>
        <a href={project.repoLink} target="_blank" title="View on GitHub"
          ><img
            alt="Repository"
            src="./assets/icons/logo-github.svg"
            class="svg h-6 tint-on-hover"
          /></a
        >
      </div>
    {/if}
  </div>
  <p class="text-xs text-subtle">
    {tagsText} ∙ {skillsText}
  </p>
  <div class="sm:hidden">
    <ProjectStatusIndicator status={project.status} />
  </div>
</div>
