<script lang="ts">
  import fetchHomePageData, { HomePageData } from "src/lib/data/home-data";
  import DataFetchBlock from "src/components/views/DataFetchBlock.svelte";
  import HomeLayout from "./HomeLayout.svelte";

  let homeData: HomePageData;
</script>

<svelte:head>
  <title>Frank Kulak</title>
</svelte:head>

<DataFetchBlock bind:data={homeData} fetchData={fetchHomePageData}>
  <HomeLayout {homeData} />
</DataFetchBlock>
