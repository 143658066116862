<script lang="ts">
  export let expanded: boolean;

  $: toggleTrayButtonIcon = expanded ? "close" : "menu";

  function toggleBottomTray() {
    expanded = !expanded;
  }
</script>

<div class="mb-4 md:hidden w-full">
  <div class="mb-4 w-full flex items-center justify-between">
    <p class="text-white">Settings &amp; Filters</p>
    <button on:click={toggleBottomTray}>
      <img
        class="svg-light h-6"
        src="./assets/icons/{toggleTrayButtonIcon}.svg"
        alt={toggleTrayButtonIcon}
      />
    </button>
  </div>
  <hr class="border-gray-500 dark:border-gray-800" />
</div>
