import { getCachedEnumsData } from "../data/enums-data";
export function getAvailableFilters(projectsData) {
    const tagCounts = new Map();
    const skillCounts = new Map();
    const count = (map) => (value) => {
        const count = map.get(value) ?? 0;
        map.set(value, count + 1);
    };
    projectsData.sections.forEach(({ projects }) => {
        projects.forEach((project) => {
            project.tags.forEach(count(tagCounts));
            project.techStack.forEach(count(skillCounts));
            project.oldTechStack?.forEach(count(skillCounts));
        });
    });
    const byCount = (map) => (a, b) => {
        const diff = map.get(b) - map.get(a);
        if (diff !== 0)
            return diff;
        if (a < b)
            return -1;
        if (a > b)
            return 1;
        return 0;
    };
    return {
        tags: [...tagCounts.keys()].sort(byCount(tagCounts)),
        skills: [...skillCounts.keys()].sort(byCount(skillCounts)),
    };
}
export function formatProjectTags(tags, options) {
    return tags.map(tag => formatProjectTag(tag, options)).join(", ");
}
export function formatProjectTag(tag, options) {
    const tagData = getCachedEnumsData()?.tags[tag];
    if (!tagData)
        return tag;
    if (options?.compact)
        return tagData.nameCompact ?? tagData.name;
    return options?.plural
        ? tagData.namePlural ?? tagData.name + "s"
        : tagData.name;
}
export function formatProjectSkills(skills, options) {
    return skills
        .map(skill => formatProjectSkill(skill, options))
        .filter(skill => skill) // get rid of non-compact
        .join(", ");
}
export function formatProjectSkill(skill, options) {
    const skillData = getCachedEnumsData()?.skills[skill];
    if (!skillData)
        return skill;
    if (options?.compact) {
        return skillData.omitFromCompact
            ? ""
            : skillData.nameCompact ?? skillData.name;
    }
    return skillData.name;
}
