<script lang="ts">
  import type { SkillsSectionData } from "src/lib/data/home-data";
  import SkillPills from "src/components/views/SkillPills.svelte";

  export let skillsData: SkillsSectionData;
</script>

<div class="flex flex-col gap-12">
  {#each skillsData.categories as skillCategory}
    <div>
      <h3 class="mb-4">{skillCategory.category}</h3>
      <SkillPills skills={skillCategory.skills} />
    </div>
  {/each}
</div>
