import { SettingsRespository, StoredBoolean, StoredJson } from "synced-web-settings";
import DocumentUtils from "./document-utils";
export const storageRepo = new SettingsRespository({
    prefix: "s:",
    storage: localStorage,
    settings: {
        isLightTheme: {
            type: StoredBoolean,
            defaultValue: false,
            callbacks: [
                (value) => DocumentUtils.toggleLightTheme(value, true)
            ]
        }
    }
});
export const vaultRepo = new SettingsRespository({
    prefix: "vault:",
    storage: sessionStorage,
    settings: {
        featuredOnly: {
            type: StoredBoolean,
            defaultValue: false
        },
        compactView: {
            type: StoredBoolean,
            defaultValue: false
        },
        tagFilter: {
            type: (StoredJson),
            defaultValue: []
        },
        techStackFilter: {
            type: (StoredJson),
            defaultValue: []
        },
    }
});
