<script lang="ts">
  import type { ExperienceInfo } from "src/lib/data/home-data";
  import SkillPills from "src/components/views/SkillPills.svelte";

  export let experience: ExperienceInfo;

  $: timespan = `${experience.startDate} — ${experience.endDate ?? "Present"}`;
  $: previousTitles = experience.formerTitles?.join(", ");
  $: hasPreviousTitles = Boolean(previousTitles);
  $: hasProjects = Boolean(experience.projects?.length);
</script>

<div class="flex flex-col gap-4">
  <div>
    <p class="uppercase text-subtle text-xs">{timespan}</p>
    <h3 class="text-lg">
      <strong class="text-color">{experience.mostRecentTitle}</strong> ∙ {experience.company}
    </h3>
    {#if hasPreviousTitles}
      <p class="text-subtle">{previousTitles}</p>
    {/if}
  </div>
  <p>{experience.summary}</p>
  {#if hasProjects}
    <ul class="projects-list text-sm">
      Projects:&nbsp;
      {#each experience.projects as project}
        <li>
          {#if Boolean(project.href)}
            <a href={project.href} target="_blank" class="text-color"
              >{project.name}</a
            >
          {:else}
            <span>{project.name}</span>
          {/if}
        </li>
      {/each}
    </ul>
  {/if}
  <SkillPills skills={experience.skills} />
</div>

<style lang="scss">
  ul.projects-list {
    display: inline;
    list-style: none;

    li {
      display: inline;

      &:after {
        content: " ∙ ";
      }

      &:last-child:after {
        content: "";
      }
    }
  }
</style>
